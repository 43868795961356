import React, {useState} from 'react';
import { Fragment } from 'react';
import { AppBar } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import GetDataBases from "../utils/DBUtils";
import {
    SelectInput,
    SimpleForm,
    TextInput,
    BooleanInput,
    DateTimeInput,
    NumberInput,
    useRedirect
} from "react-admin";
import AsyncSelect,  { useAsync } from 'react-select/async';


const MyAppBar = props =>
    <AppBar {...props} >


    </AppBar>
export default MyAppBar;
