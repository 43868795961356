import EditGuesser from '@api-platform/admin/lib/EditGuesser';
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import InputGuesser from '@api-platform/admin/lib/InputGuesser';
import React from 'react';

export default props => (
  <EditGuesser {...props}>
    <FieldGuesser source={"filename"}/>
    <InputGuesser source={"preventAutoClean"} />
  </EditGuesser>
);
