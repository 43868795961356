import {FirebaseAuthProvider} from "react-admin-firebase";
import firebase from './../security/firebase';

const authProvider = FirebaseAuthProvider(firebase, {logging: true});

export const checkAuth = () =>
  Promise.resolve().then(() => {
    if (localStorage.getItem("token")) return Promise.resolve();
    authProvider.getJWTToken().then(token => {
      if (null !== token) {
        window.localStorage.setItem('token', token);
          window.location.reload();
      }
    });

    return Promise.resolve()
        .then(() => localStorage.setItem("token", authProvider.getJwtToken()))
        .catch(() =>
          Promise.resolve()
           // .then(()=> alert("test"))
            .then(() => localStorage.removeItem("token"))
            .then(() => Promise.reject())
        );
  });

export const logout = () =>
  Promise.resolve()
    .then(() => authProvider.logout() )
    .then(() => localStorage.removeItem("token"))

export default {
  // authentication
  login: () =>
    Promise.reject("Unrecheable code (authentication from checkAuth"),
  checkError: (error) => Promise.resolve(error).then((error) => console.error(error)),
  checkAuth: checkAuth,
  logout: logout,
  getIdentity: () => Promise.reject(),
  // authorization
  getPermissions: (params) => Promise.reject(),
};
