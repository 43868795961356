import CreateGuesser from "@api-platform/admin/lib/CreateGuesser";
import {FileField, FileInput} from "react-admin";
import React from "react";

export default props => (
    <CreateGuesser {...props}>
        <FileInput source={"file"}>
            <FileField source="src" title="title" />
        </FileInput>
    </CreateGuesser>
);