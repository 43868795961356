import React from "react";
import {Redirect, Route} from "react-router-dom";
import {
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import {FirebaseAuthProvider} from "react-admin-firebase";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import firebase from './../security/firebase';
import {checkAuth, logout} from './authProvider';
import env from "./env";

const authProvider = FirebaseAuthProvider(firebase, {logging: true});

const entrypoint = env.entrypoint+'/api';



const getHeaders = () => localStorage.getItem('token') ? {
    "Firebase-Auth": `Bearer ${localStorage.getItem('token')}`,
} : {};

const fetchHydra = async (url, options = {}) => {
  // we wait for the auth if needed
  await checkAuth().catch((error) => error);
  return baseFetchHydra(url, { ...options, headers: getHeaders() });
};

const RedirectToLogin = () => {
  if (localStorage.getItem("token")) {
    return <></>;
  }

  return <Redirect to="/login" />;
};

const apiDocumentationParser = async (entrypoint) => {
  // we wait for the auth if needed
  await checkAuth().catch((error) => error);

  try {
    const { api } = await parseHydraDocumentation(entrypoint, {
      headers: getHeaders(),
    });
    return { api };
  } catch (result) {
    if (result.status === 401 ||result.status === 403 ) {
      await logout();

      return {
        api: result.api,
        customRoutes: [<Route path="/" component={RedirectToLogin} />],
      };
    }

    throw result;
  }
};


const customDataProvider = abstractDataProvider => ({
    ...abstractDataProvider,
    getList: (resource, params) => {
        if(!!params.pagination && !!params.pagination.perPage){
            if(!params.filter) params.filter={}
            params.filter.pagination=true;
        }
        return abstractDataProvider.getList(resource, params);
    },
    create: (resource, params) => {
            if (resource === 'media' || params.data.file) {
                let file = params.data.file;

                return fetch(env.entrypoint+`/admin/media/upload`, {
                    headers:  getHeaders()
                })
                    .then(res => res.json())
                    .then(data => {
                        const formData = new FormData();
                        for (let [key, value] of Object.entries(data.fields)) {
                            formData.append(key, value);
                        }
                        formData.append('file', file.rawFile);

                        return fetch(data['url'], {redirect: 'follow', method: 'POST', body: formData})
                    })
                    // .then(res => {console.log(res); res.text().then(console.log); return res.json();})
                    // // .then(json => {
                    // //     return {
                    // //         data: {
                    // //             ...json
                    // //         }
                    // //     }
                    // // })
                    // .then(json => {
                    //     console.log(json);
                    //     return json;
                    // })
                    // // .then(JSON.stringify);

                // TODO : return a json response like {data: ...json } (fix cors issue and then return this kind of result
            
            }

        return abstractDataProvider.create(resource, params);
    },
});

export default customDataProvider(
  baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser)
);