import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {UrlField} from "react-admin";
import React from "react";

export default props => (
    <ListGuesser {...props} perPage={25}>
        <FieldGuesser source={"filename"}/>
        <UrlField source={"webPath"}/>
        <FieldGuesser source={"preventAutoClean"}/>
        <FieldGuesser source={"type"}/>
    </ListGuesser>
);