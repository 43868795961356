import React from "react";
import LoginPage from "./layout/Login";
import dataProvider from "./utils/dataProvider";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import CustomLayout from "./layout/Layout";
import Media from './components/Media/Media';
import AssetBundleMedia from './components/Media/AssetBundleMedia';
import {Redirect, Route} from 'react-router-dom';
import MyAccount from "./layout/MyAccount";
import authProvider from "./utils/authProvider";
import env from "./utils/env";


export default () => (
  <HydraAdmin
    entrypoint={env.entrypoint}
    loginPage={LoginPage}
    dataProvider={dataProvider}
    layout={CustomLayout}
    authProvider={authProvider}
  >
    <ResourceGuesser name={"experiences"} />
    {Media}
    {AssetBundleMedia}
  </HydraAdmin>
);
