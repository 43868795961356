import * as React from "react";
import { useSelector } from "react-redux";
import { MenuItemLink, getResources } from "react-admin";

const displayMenuItem = (resource, onMenuClick, open) => {
  let name = resource.name.split("_");

  for (let i = 0; i < name.length; i++) {
      name[i] = name[i][0].toUpperCase() + name[i].substr(1);
  }
  name.join(" ");

  return (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={name}
        />
  );
};
const Menu = ({ onMenuClick }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      {resources.map((resource) =>
        displayMenuItem(resource, open, onMenuClick)
      )}
    </div>
  );
};

export default Menu;
