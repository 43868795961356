const entrypoint_dev = `${process.env.REACT_APP_API_ENTRYPOINT_DEV}`;
const entrypoint_prod = `${process.env.REACT_APP_API_ENTRYPOINT}`;

const host_dev = `${process.env.REACT_APP_HOST_DEV}`;
const host_prod = `${process.env.REACT_APP_HOST}`;

const env_current = `${process.env.REACT_APP_ENV}`;

const env_prod = "master"

export default {
  entrypoint: env_current === env_prod ? entrypoint_prod : entrypoint_dev,
  host:  env_current === env_prod ? host_prod : host_dev,
}
