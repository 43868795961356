import CreateGuesser from "@api-platform/admin/lib/CreateGuesser";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";
import { ReferenceInput, SelectInput } from 'react-admin';
import React from "react";


const ParameterTypes = [
    {id : 0, name:"WINDOWS" },
    {id :1, name:"IOS" },
    {id : 2, name:"Android" },
    {id : 3, name:"OSX" },
]


export default props => (
    <CreateGuesser {...props}>
        <ReferenceInput source={"experience"} reference={'experiences'} perPage={null}>
            <SelectInput optionText={`name`} />
        </ReferenceInput>
        <InputGuesser source={"name"} />
        <InputGuesser source={"description"} />
        <InputGuesser source={"localObjectId"} />
        <InputGuesser source={"version"} />
        <InputGuesser source={"isProduction"} />
        <InputGuesser source={"main"} />
        <SelectInput
            label={"platform"}
            source={"platform"}
            choices={ParameterTypes}
        />
        <ReferenceInput source={"androidAsb"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
        <ReferenceInput source={"androidAsbManifest"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
        <ReferenceInput source={"iosAsb"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
        <ReferenceInput source={"iosAsbManifest"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
        <ReferenceInput source={"winEditorAsb"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
        <ReferenceInput source={"winEditorAsbManifest"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
        <ReferenceInput source={"macEditorAsb"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
        <ReferenceInput source={"macEditorAsbManifest"} reference={'media'} perPage={null}>
            <SelectInput optionText={`filename`} />
        </ReferenceInput>
    </CreateGuesser>
);