import * as React from "react";
import { Layout } from "react-admin";
import Menu from "./Menu";
import AppBar from "./AppBar";
import MyAppBar from "./AppBar";

const CustomLayout = ({ children, ...props }) => {
  return (
    <Layout {...props} menu={Menu} appBar={MyAppBar}>
      {children}
    </Layout>
  );
};

export default CustomLayout;
