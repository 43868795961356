import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import ReferenceField from "@api-platform/admin/lib/FieldGuesser";
import React from "react";

export default props => (
    <ListGuesser {...props} perPage={100}>
         <ReferenceField  source="experience" reference="experiences" required>
             <FieldGuesser source="name" />
        </ReferenceField>
        <FieldGuesser source={`name`} />
        <FieldGuesser source={`description`} />
        <FieldGuesser source={`localObjectId`} />
        <FieldGuesser source={`version`} />
        <FieldGuesser source={`platform`} />
    </ListGuesser>
);